@import "./normalize.css";
@import "./skeleton.less";
@import "@fortawesome/fontawesome-free/less/fontawesome.less";
@import "@fortawesome/fontawesome-free/less/solid.less";

@primary-color: #006aca;
@link-color: #006aca;
@background-color: #f0f0f0;

/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto'), local('Roboto-Regular'), url(fonts/Roboto-Regular-LatinExt.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(fonts/Roboto-Bold-LatinExt.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(fonts/RobotoCondensed-Regular-LatinExt.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: bold;
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(fonts/RobotoCondensed-Bold-LatinExt.woff2) format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto'), local('Roboto-Regular'), url(fonts/Roboto-Regular-Latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(fonts/Roboto-Bold-Latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(fonts/RobotoCondensed-Regular-Latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: bold;
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(fonts/RobotoCondensed-Bold-Latin.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}


* {
    font-family: 'Roboto', sans-serif;
    -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

html { font-size:84%; }

body {
    background-color: @background-color;
    -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
    -webkit-user-select: none;    
}

h1 {
    font-size: 22px;
    font-weight: bold;
    line-height: 40px;
    display: block;
}

#screen { position:relative; top:0; padding:15px; height:100vh; box-sizing:border-box; }

#splash { font-family:Arial,Helvetica,sans-serif; font-size:250px; line-height:250px; font-weight:bold; text-align:center; color:#cccccc; top:0; margin:0 auto; width:100%; }

#response { background-color:#fff; padding:10px; margin:0 auto; text-align:center; }
#response.success {	border:10px solid #3fb02a; }
#response.warning {	border:10px solid #fecb00; }
#response.error { border:10px solid #cb1112; }

#response.offline {
    background-color:#fff;
    background-image: linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 50%, #f0f0f0 50%, #f0f0f0 75%, transparent 75%, transparent);
    background-repeat: repeat;
    background-size: 40px 41px;
}

#response .code { display:block; overflow:hidden; line-height:1.4; max-height:56px; }
#response p { margin:0; font-family:'Roboto Condensed',sans-serif; line-height:1.4; }
#response p.error, #response p.success, #response p.warning { font-family:Roboto,sans-serif; font-weight:bold; }
#response p.error { color:#cb1112; }
#response p.success { color:#3fb02a; }
#response p.warning { color:#fecb00; }
#response .more { line-height:1.5rem; font-size:1.5rem; font-weight:bold; }
#response .more a { color:#222; text-decoration:none; }
#response .code { }

#response .button-primary { background-color:#3fb02a; border-color:#3fb02a; margin:0;}
#response .button { margin:1rem 0 0 0; }

#login-form { }

#access-form { padding-top:60px; }
#access-form .button { height:auto;	white-space:normal; line-height:inherit; text-transform:none; }

#settings-form { padding-top: 70px; }
#settings-form .accessName { text-overflow:ellipsis; height:auto; white-space:normal; line-height:inherit; text-transform:none; }
#settings-form .row.user { padding-bottom:20px; }
#settings-form .version { font-size:11px; text-align:right; font-style:italic; }
#settings-form #stats-btn { float:right; }

#adv-settings-form {
    .settings-row {
        font-size: 0.8em;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem 1rem;
        padding: 1rem 0;
        border-bottom: 1px solid darken(@background-color, 10%);

        label {
            flex: 1 0 100px;
            align-self: center;
            text-align: left;
            margin: 0;
        }

        .settings-input {
            flex: 0 0 auto;
            max-width: 100%;
            text-align: left;

            input, .input-spinner {
                margin: 0;
            }
        }
    }
}

#show-stats {
    font-size:12px;
}

#console-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    h1 {
        flex: 0;
    }

    #console-container {
        background-color: darken(@background-color, 10%);
        padding: 0.5rem;
        flex: 1;
        overflow-y: auto;
        font-size: 14px;
        p {
            margin-bottom: 0.2rem;
        }
    }
}

#validate-form { padding-top:10px; }
#validate-form form { position:fixed; left:50%; bottom:0; -ms-transform:translateX(-50%); -webkit-transform:translate(-50%,0);	transform:translate(-50%,0); }

.container { background: @background-color; }
.box { background-color: @background-color; }
.box form { display:block; max-width:400px; margin:0 auto; }
.centered { display:block; max-width:400px; position:absolute; left:50%; top:50%; -ms-transform:translateX(-50%) translateY(-50%); -webkit-transform:translate(-50%,-50%); transform:translate(-50%,-50%); }
.fullscreen { display:block; position:absolute; padding:15px 20px; margin:0; top:0; left:0; right:0; width:100%; max-width:100%; z-index:2; zoom:1; min-height: 100vh; }
    
#loader { display:none; position:absolute; top:0; bottom:0; left:0; right:0; width:100%; height:100%; background: #FFFFFF url(../img/loader204.gif) no-repeat 50% 50%;
    opacity: 0.9; filter: alpha(opacity=90); zoom:1; z-index:4;
    text-align:center; padding-top:10px; 
}
#loader span {  }

#recall {
    display:block; position:absolute; top:0px; right:0px;
    width: @input-height; height:@input-height; line-height:@input-height;
    font-size: 24px; text-align:center;

    i { font-size: 24px }
}

#info {line-height:40px; font-size:0.8em; text-align:left; margin:0 50px 0 5px; overflow:hidden; white-space:nowrap; }
#info user { display:inline-block; width:0.8em; height:0.8em; border-radius:0.4em; margin-right:5px; text-indent:-9999px; overflow:hidden; }
#info user.offline { background-color:gray; }
#info user.online { background-color:green; }
#info user.error { background-color:red; }
#info .accessName { white-space: nowrap; text-overflow:ellipsis;  }

#back-btn {
    position:absolute; top:15px; left:15px;
    text-decoration:none;
	width:40px; height:40px; line-height:40px;
	text-align:center;
    z-index:9999;
    color: @primary-color;
    display: none;
}

#settings-btn { font-family:Arial,Helvetica,sans-serif;	font-size:32px;	font-weight:bold;
	position:absolute; top:15px; right:15px; border-radius: 4px; text-decoration:none;
	width:40px; height:40px; line-height:40px;
	text-align:center;
    z-index:3;
}

#settings-btn span {
    margin:10px;
    display:block;
    position: relative;
    text-indent:-9999px;
    overflow:hidden;
}
#settings-btn span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: 0.15em;
    background: @primary-color;
    box-shadow: 
    0 0.25em 0 0 @primary-color,
    0 0.5em 0 0 @primary-color;
}
#settings-btn.active  {
	background:@primary-color;
}
#settings-btn.active span:before {
    background: #FFFFFF;
    box-shadow: 
    0 0.25em 0 0 #FFFFFF,
    0 0.5em 0 0 #FFFFFF;
}

#settings-btn.debug span:before { 
    background: red;
    box-shadow: 
    0 0.25em 0 0 red,
    0 0.5em 0 0 red;
}

.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
    padding: 0 15px;
    background-color: #fff;
}

#update-info {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#download-progress {
    height: 40px;
}

.input-spinner {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    
    button {
        flex: 0 0 @input-height;
        margin-bottom: 0;
        border-radius: 0;
    }
    input {
        width: 8em;
        margin: 0;
        border-radius: 0;
    }
    button:first-child {
        border-top-left-radius: @global-radius;
        border-bottom-left-radius: @global-radius;
    }
      
    button:last-child {
        border-top-right-radius: @global-radius;
        border-bottom-right-radius: @global-radius;
    }
}

body.is-iabilet-ios-app { background: #E3E3EA; }
.is-iabilet-ios-app .container { background: #E3E3EA; }
.is-iabilet-ios-app .box { background: #E3E3EA; }
.is-iabilet-ios-app #back-btn { display: block; }

.profile-image {
    height: 128px; 
    max-height: 128px; 
    width: 128px; 
    max-width: 128px; 
    background-color: #aaa;
    cursor: zoom-in;
}

.profile-image-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.9); /* Black background with opacity */
}

.modal-profile-image {
    margin: auto;
    display: block;
    width: 80%;
    min-width: 80%;
    max-width: 700px;
    max-height: 80%;
}
